import { Box, Button, Divider, Paper, Stack, Typography, useMediaQuery } from '@mui/material'
import { useLanguage } from '../contexts'

export function Header() {
  const { t, language, changeLanguage } = useLanguage()
  const toLanguage = language === 'kz' ? 'ru' : 'kz'
  const isDesktop = useMediaQuery('(min-width:900px)')

  const handleChangeLang = () => {
    changeLanguage(toLanguage)
  }

  return (
    <>
      {isDesktop ? (
        <Paper sx={{ bgcolor: 'primary.main', color: 'white' }} square>
          <Stack direction='row' alignItems='center' spacing={1} p={1.5}>
            <Box mt={1}>
              <img src='/logo192.png' width={64} alt='' />
            </Box>
            <Box>
              <Typography variant='h6'>{t('header.title')}</Typography>
              <Typography variant='body2'>{t('header.description')}</Typography>
            </Box>
          </Stack>
          <Divider sx={{ bgcolor: 'rgba(255, 255, 255, 0.2)' }} />
          <Stack direction='row' alignItems='center' justifyContent='space-between' px={1.5} py={1}>
            <Typography variant='subtitle2'>{t('header.region')}</Typography>
            <Button color='inherit' size='small' onClick={handleChangeLang}>
              {toLanguage.toUpperCase()}
            </Button>
          </Stack>
        </Paper>
      ) : (
        <Paper sx={{ bgcolor: 'primary.main', color: 'white' }} square>
          <Stack direction='row' alignItems='center' spacing={1} px={1.5} py={1}>
            <Box>
              <img src='/logo192.png' width={36} alt='' />
            </Box>
            <Typography variant='subtitle2' flexGrow={1}>
              {t('header.region')}
            </Typography>
            <Button color='inherit' size='small' sx={{ px: 0 }} onClick={handleChangeLang}>
              {toLanguage.toUpperCase()}
            </Button>
          </Stack>
        </Paper>
      )}
    </>
  )
}
