import { Box, Card, CardContent, Chip, Divider, IconButton, Stack, Typography } from '@mui/material'
import { ArrowBack as BackIcon, CheckCircle as CheckIcon } from '@mui/icons-material'

import { useLandsContext, useLanguage } from '../contexts'
import { getLandById } from '../data/api'
import { LangText } from '../components'

interface IProps {
  id: number
}

export function LandDetail({ id }: IProps) {
  const { t } = useLanguage()
  const { setCurrentLandId } = useLandsContext()
  const land = getLandById(id)

  const handleBack = () => {
    setCurrentLandId(undefined)
  }

  if (!land) {
    return null
  }

  return (
    <Box p={2}>
      <Stack direction='row' spacing={1} alignItems='center' mb={2}>
        <IconButton onClick={handleBack}>
          <BackIcon />
        </IconButton>
        <LangText variant='h5' value={land.title} />
      </Stack>

      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant='h6' mb={1}>
            {land.size} га
          </Typography>
          <LangText value={land.company_name} />
          {!!land.region && (
            <LangText value={land.region.title} variant='body2' color='text.secondary' />
          )}
          <Typography variant='body2' color='text.secondary'>
            {land.contacts}
          </Typography>
        </CardContent>
      </Card>

      <Stack direction='row' flexWrap='wrap' spacing={1} mb={3}>
        {land.types.map((type) => (
          <Chip
            key={`type-${type.id}`}
            label={<LangText value={type.title} variant='body2' />}
            icon={<CheckIcon />}
            color='success'
            size='small'
            variant='outlined'
            sx={{ mb: 1 }}
          />
        ))}
      </Stack>

      <LangText mb={3} value={land.description} />

      <Typography variant='h6'>{t('common.animals')}</Typography>
      <Divider sx={{ mt: 1, mb: 2 }} />
      <Stack direction='row' flexWrap='wrap' spacing={1} mb={3}>
        {land.animals.map((animal) => (
          <Box key={`an-${animal.id}`} sx={{ pb: 1 }}>
            <Chip
              label={<LangText value={animal.title} variant='body2' />}
              color='info'
              variant='outlined'
            />
          </Box>
        ))}
      </Stack>
    </Box>
  )
}
