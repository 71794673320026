import React, { createContext, useContext, useEffect, useState } from 'react'
import { ILang } from '../data/types'
import { locales } from '../locales'

interface ILanguageContextProps {
  language: ILang
  changeLanguage: (lng: ILang) => void
  t: (key: string) => string
}

export const LanguageContext = createContext<ILanguageContextProps>({
  language: 'ru',
  changeLanguage: () => null,
  t: () => '',
})

export function LanguageProvider({ children }: React.PropsWithChildren) {
  const [language, setLanguage] = useState<ILang>('ru')

  useEffect(() => {
    const lang = window.localStorage.getItem('_lang')

    if (lang && lang !== language) {
      setLanguage(lang as ILang)
    }
  }, [])

  const changeLanguage = (lng: ILang) => {
    setLanguage(lng)
    window.localStorage.setItem('_lang', lng)
  }

  type LangObjType = { [key: string]: string | LangObjType }

  const t = (key: string) => {
    const langObj: LangObjType = locales[language]
    const keys = key.split('.')

    let value = key
    let lastObj = langObj
    for (let i = 0; i < keys.length; i++) {
      if (!lastObj[keys[i]]) {
        break
      }
      if (typeof lastObj[keys[i]] === 'string') {
        value = lastObj[keys[i]] as string
        break
      }
      lastObj = lastObj[keys[i]] as LangObjType
    }

    return value
  }

  return (
    <LanguageContext.Provider
      value={{
        language,
        changeLanguage,
        t,
      }}
    >
      {children}
    </LanguageContext.Provider>
  )
}

export const useLanguage = () => useContext(LanguageContext)
