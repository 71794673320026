import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material'
import { LangText } from '../components'

import { useLandsContext, useLanguage } from '../contexts'

export function LandsList() {
  const { t } = useLanguage()
  const { lands, setCurrentLandId } = useLandsContext()

  const handleClick = (id: number) => {
    setCurrentLandId(id)
  }

  return (
    <Box>
      <Box px={2} py={1}>
        <Typography variant='subtitle2'>
          {t('common.total')}: {lands.length}
        </Typography>
      </Box>
      <Divider />
      <List disablePadding>
        {lands.map((land) => (
          <ListItem
            key={`land-${land.id}`}
            disablePadding
            sx={{ borderBottom: `1px solid rgba(0, 0, 0, 0.05)` }}
          >
            <ListItemButton onClick={() => handleClick(land.id)}>
              <ListItemText
                primary={<LangText variant='subtitle1' value={land.title} />}
                secondary={`${t('common.area')}: ${land.size} га`}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  )
}
