import { ThemeOptions } from '@mui/material'
import { grey } from '@mui/material/colors'

const PRIMARY = {
  lighter: '#92F3E4',
  light: '#2EBBBB',
  main: '#00838F',
  dark: '#00667A',
  darker: '#002844',
}
const SECONDARY = {
  lighter: '#A9D3FF',
  light: '#5EA0FF',
  main: '#2979FF',
  dark: '#1D5DDB',
  darker: '#07217A',
}
const INFO = {
  lighter: '#AFBFFE',
  light: '#6A82FB',
  main: '#3956F9',
  dark: '#2941D6',
  darker: '#0A1477',
}
const SUCCESS = {
  lighter: '#BFF6AF',
  light: '#62C95C',
  main: '#2EA532',
  dark: '#218D2E',
  darker: '#084F24',
}
const WARNING = {
  lighter: '#FFEA99',
  light: '#FFCD3F',
  main: '#FFB600',
  dark: '#DB9600',
  darker: '#7A4800',
}
const ERROR = {
  lighter: '#FABCA9',
  light: '#E46159',
  main: '#D3282D',
  dark: '#B51D2F',
  darker: '#65072B',
}

export const options: ThemeOptions = {
  palette: {
    primary: { ...PRIMARY, contrastText: '#fff' },
    secondary: { ...SECONDARY, contrastText: '#fff' },
    info: { ...INFO, contrastText: '#fff' },
    success: { ...SUCCESS, contrastText: '#fff' },
    warning: { ...WARNING, contrastText: '#fff' },
    error: { ...ERROR, contrastText: '#fff' },
    background: {
      default: '#f7f7ff',
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    button: {
      textTransform: 'none',
      fontWeight: 500,
      fontSize: 16,
    },
    h1: {
      fontWeight: 700,
    },
    h2: {
      fontWeight: 700,
    },
    h3: {
      fontWeight: 500,
    },
    h4: {
      fontWeight: 500,
    },
    h5: {
      fontWeight: 500,
    },
    subtitle1: {
      fontWeight: 500,
    },
  },
  shape: {
    borderRadius: 6,
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          height: 48,
        },

        sizeSmall: {
          height: 36,
        },
      },
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          border: `1px solid ${grey[100]}`,
        },
      },
    },
    MuiAppBar: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        margin: 'normal',
        fullWidth: true,
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid #f1f1f1',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          background: grey[50],
          '& fieldset': {
            borderColor: grey[300],
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: 'rgba(0, 0, 0, 0.05)',
        },
      },
    },
  },
}
