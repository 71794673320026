import { Typography, TypographyProps } from '@mui/material'

import { useLanguage } from '../contexts'
import { ILangValue } from '../data/types'

interface IProps extends TypographyProps {
  value: ILangValue
}

export function LangText({ value, ...props }: IProps) {
  const { language } = useLanguage()

  return <Typography {...props}>{value[language] ?? ''}</Typography>
}
