import { useEffect, useRef, useState } from 'react'
import { MapContainer, TileLayer, useMap, GeoJSON, ZoomControl, LayersControl } from 'react-leaflet'
import L, { GeoJSON as GeoJSONType, LatLngBoundsExpression } from 'leaflet'

import { stringToColor } from '../utils'
import { useLandsContext } from '../contexts'
import { geoJSONDataByLands, getLandGeoById } from '../data/api'

export function MapWrapper() {
  return (
    <MapContainer
      id='map'
      center={[49.5219664, 68.6505615]}
      zoom={12}
      minZoom={7}
      style={{ height: '100%' }}
      zoomControl={false}
      // attributionControl={false}
    >
      <LayersControl position='topright'>
        <LayersControl.BaseLayer checked name='По умолчанию'>
          <TileLayer
            // attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            // url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
            url='http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}'
            subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name='Спутник'>
          <TileLayer
            attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
            url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name='Спутник2'>
          <TileLayer
            attribution='&copy; NASA Blue Marble, image service by OpenGeo'
            url='https://gibs-{s}.earthdata.nasa.gov/wmts/epsg3857/best/BlueMarble_ShadedRelief_Bathymetry/default//EPSG3857_500m/{z}/{y}/{x}.jpeg'
            maxNativeZoom={8}
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name='Рельеф'>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
            url='https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png'
            maxZoom={17}
          />
        </LayersControl.BaseLayer>
      </LayersControl>
      <ZoomControl position='topright' />
      <MapContent />
    </MapContainer>
  )
}

function MapContent() {
  const { lands, currentLandId, setCurrentLandId } = useLandsContext()
  const map = useMap()
  const isMaxBounded = useRef(false)
  const geoJsonRef = useRef<GeoJSONType | null>(null)
  const [geoJsonData, setGeoJsonData] = useState(() => geoJSONDataByLands(lands))

  function setInitialBounds() {
    const bounds = geoJsonRef.current?.getBounds()
    map.closePopup()

    if (bounds) {
      map.flyToBounds(bounds)

      if (!isMaxBounded.current) {
        map.setMaxBounds(bounds)
        isMaxBounded.current = true
      }
    }
  }

  function goToLand(id: number) {
    const feature = getLandGeoById(id)

    if (!feature || !feature.geometry) return

    const boundsLiteral: LatLngBoundsExpression = feature.geometry.coordinates[0].map((c) => {
      return [c[1], c[0]]
    })

    const bounds = new L.LatLngBounds(boundsLiteral)

    L.popup()
      .setLatLng(bounds.getCenter())
      .setContent(
        `
        <b>${feature.properties['Название']}</b><br />
        <span>${feature.properties['Площадь']}</span>
      `
      )
      .openOn(map)

    map.flyToBounds(bounds)
  }

  useEffect(() => {
    if (currentLandId) {
      goToLand(currentLandId)
    } else {
      setInitialBounds()
    }
  }, [currentLandId])

  useEffect(() => {
    const geoData = geoJSONDataByLands(lands)
    setGeoJsonData(geoData)

    if (geoJsonRef.current) {
      geoJsonRef.current.clearLayers().addData(geoData)
      const bounds = geoJsonRef.current.getBounds()

      if (bounds && Object.keys(bounds).length > 0) {
        map.fitBounds(bounds)
      }
    }
  }, [lands.length])

  return (
    <GeoJSON
      // key={`geo-${Math.random()}`}
      ref={geoJsonRef}
      data={geoJsonData}
      style={(feature) => ({
        color: stringToColor(feature?.properties['Название'] || 'na'),
      })}
      onEachFeature={(feature, layer) => {
        const id = Number(feature.properties.NN)

        layer.on({
          click() {
            setCurrentLandId(id)
            // history.pushState(null, '', `#${feature.properties.NN}`)
          },
        })
      }}
    />
  )
}
