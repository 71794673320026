import { Box, Fab, styled } from '@mui/material'

import { ViewMode } from '../views'
import MapIcon from '@mui/icons-material/Map'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'

type Props = { mode: ViewMode; onClick: (mode: ViewMode) => void }

export const SwitchButton = ({ mode, onClick }: Props) => {
  if (mode === ViewMode.DETAIL) {
    return null
  }

  return (
    <StyledBox>
      <Fab
        color='primary'
        onClick={() => onClick(mode === ViewMode.LIST ? ViewMode.MAP : ViewMode.LIST)}
      >
        {mode === ViewMode.LIST ? <MapIcon /> : <FormatListBulletedIcon />}
      </Fab>
    </StyledBox>
  )
}

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'none',
  position: 'fixed',
  bottom: 20,
  right: 20,
  zIndex: '999',
  [theme.breakpoints.down('md')]: {
    display: 'block',
  },
}))
