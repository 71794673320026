import React, { createContext, useContext, useEffect, useState } from 'react'
import { getMainData } from '../data/api'
import { ILand, ILandsFilter } from '../data/types'

interface ILandsContextProps {
  lands: ILand[]
  currentLandId?: number
  setCurrentLandId: (id?: number) => void
  filter: ILandsFilter
  setFilter: (filter: ILandsFilter) => void
}

export const LandsContext = createContext<ILandsContextProps>({
  lands: [],
  currentLandId: undefined,
  setCurrentLandId: () => null,
  filter: {},
  setFilter: () => null,
})

export function LandsProvider({ children }: React.PropsWithChildren) {
  const [filter, setFilter] = useState<ILandsFilter>({})
  const [lands, setLands] = useState(() => getMainData({}))
  const [currentLandId, setCurrentLandIdFn] = useState<number>()

  useEffect(() => {
    window.addEventListener('hashchange', () => {
      const idHash = window.location.hash === '' ? '' : window.location.hash.split('#').pop()
      const idHashChecked: number | undefined =
        idHash && idHash !== '' ? parseInt(idHash, 10) : undefined
      setCurrentLandIdFn(idHashChecked)
    })
  }, [])

  const setCurrentLandId = (id?: number) => {
    window.location.hash = `${id}`
  }

  useEffect(() => {
    setLands(() => getMainData(filter))
  }, [filter])

  return (
    <LandsContext.Provider
      value={{
        lands,
        currentLandId,
        setCurrentLandId,
        filter,
        setFilter,
      }}
    >
      {children}
    </LandsContext.Provider>
  )
}

export const useLandsContext = () => useContext(LandsContext)
