import { useCallback, useEffect, useState } from 'react'
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  InputBase,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
} from '@mui/material'
import {
  PlaceOutlined as PinIcon,
  Tune as MoreFilterIcon,
} from '@mui/icons-material'
import { getAnimals, getHuntingTypes, getRegions } from '../data/api'
import { LangText } from './LangText'
import { useLandsContext, useLanguage } from '../contexts'
import { debounce, omit } from 'lodash'
import ClearIcon from '@mui/icons-material/Clear'

export function SearchBox() {
  const { t } = useLanguage()
  const { filter, setFilter } = useLandsContext()
  const [filterVisible, setFilterVisible] = useState(
    () => Object.keys(omit(filter, 'search')).length > 0
  )
  const [searchText, setSearchText] = useState<string>(filter.search ?? '')

  const clearInputHandler = ()=>{
    setSearchText('')
  }

  const updateSearchQuery = () => {
    setFilter({ ...filter, search: searchText })
  }

  const delayedUpdateSearch = useCallback(debounce(updateSearchQuery, 700), [searchText])

  useEffect(() => {
    delayedUpdateSearch()

    return delayedUpdateSearch.cancel
  }, [delayedUpdateSearch, searchText])

  const toggleFilter = () => {
    if (filterVisible) {
      setFilter({ search: filter.search })
    }
    setFilterVisible((prev) => !prev)
  }

  return (
    <Paper elevation={2} square>
      <Box p={1} sx={{ display: 'flex', alignItems: 'center' }}>
        <InputBase
          sx={{ ml: 1, flex: 1, bgcolor: 'white' }}
          placeholder={t('common.search')}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          startAdornment={
            <InputAdornment position='start'>
              <PinIcon />
            </InputAdornment>
          }
          endAdornment={
            searchText.length > 0 ? (
              <IconButton onClick={clearInputHandler} size='small' sx={{ mr: 0.5 }}>
                <ClearIcon fontSize='small' />
              </IconButton>
            ) : undefined
          }
        />
        <Divider sx={{ height: 28, m: 0.5 }} orientation='vertical' />
        <IconButton onClick={toggleFilter}>
          {filterVisible ? <MoreFilterIcon color='success'/> : <MoreFilterIcon />}
        </IconButton>
      </Box>
      {filterVisible && (
        <>
          <Divider />
          <Box sx={{ p: 1.5 }}>
            <Filter />
          </Box>
        </>
      )}
    </Paper>
  )
}

function Filter() {
  const { t } = useLanguage()
  const animalsMap = getAnimals()
  const huntingTypesMap = getHuntingTypes()
  const regionsMap = getRegions()
  const { filter, setFilter } = useLandsContext()

  const [animals, setAnimals] = useState<number[]>(filter.animals ?? [])
  const [huntingTypes, setHuntingTypes] = useState<number[]>(filter.types ?? [])
  const [regions, setRegions] = useState<number[]>(filter.regions ?? [])

  const handleChangeAnimals = (event: SelectChangeEvent<number[]>) => {
    const {
      target: { value },
    } = event
    setAnimals(value as number[])
  }

  const handleChangeHuntingTypes = (event: SelectChangeEvent<number[]>) => {
    const {
      target: { value },
    } = event
    setHuntingTypes(value as number[])
  }

  const handleChangeRegions = (event: SelectChangeEvent<number[]>) => {
    const {
      target: { value },
    } = event
    setRegions(value as number[])
  }

  const handleAnimalClearClick = () => {
    setAnimals([])
  }

  const handleRegionsClearClick = () => {
    setRegions([])
  }

  const handleHuntingTypesClearClick = () => {
    setHuntingTypes([])
  }

  useEffect(() => {
    setFilter({ ...filter, animals })
  }, [animals.length])

  useEffect(() => {
    setFilter({ ...filter, types: huntingTypes })
  }, [huntingTypes.length])

  useEffect(() => {
    setFilter({ ...filter, regions })
  }, [regions.length])

  return (
    <Box>
      <Stack direction='row' spacing={1}>
        <FormControl size='small' fullWidth sx={{ mb: 1 }}>
          <InputLabel>{t('common.animals')}</InputLabel>
          <Select
            multiple
            value={animals}
            onChange={handleChangeAnimals}
            input={<OutlinedInput label={t('common.animals')} />}
            renderValue={(selected) => `${t('common.selected')} ${selected.length}`}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 240,
                },
              },
            }}
            endAdornment={
              animals.length > 0 ? (
                <IconButton onClick={handleAnimalClearClick} size='small' sx={{ mr: 0.5 }}>
                  <ClearIcon fontSize='small' />
                </IconButton>
              ) : undefined
            }
          >
            {animalsMap.map((a) => (
              <MenuItem key={`animal-${a.id}`} value={a.id} dense sx={{ px: 0.5, py: 0 }}>
                <Checkbox checked={animals.includes(a.id)} />
                <ListItemText primary={<LangText value={a.title} variant='body2' />} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl size='small' fullWidth>
          <InputLabel>{t('common.huntingTypes')}</InputLabel>
          <Select
            multiple
            value={huntingTypes}
            onChange={handleChangeHuntingTypes}
            input={<OutlinedInput label={t('common.huntingTypes')} />}
            renderValue={(selected) => `${t('common.selected')} ${selected.length}`}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 240,
                },
              },
            }}
            endAdornment={
              huntingTypes.length > 0 ? (
                <IconButton onClick={handleHuntingTypesClearClick} size='small' sx={{ mr: 0.5 }}>
                  <ClearIcon fontSize='small' />
                </IconButton>
              ) : undefined
            }
          >
            {huntingTypesMap.map((t) => (
              <MenuItem key={`type-${t.id}`} value={t.id} dense sx={{ px: 0.5, py: 0 }}>
                <Checkbox checked={huntingTypes.includes(t.id)} />
                <ListItemText primary={<LangText value={t.title} variant='body2' />} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
      <FormControl size='small' fullWidth>
        <InputLabel>{t('common.regions')}</InputLabel>
        <Select
          multiple
          value={regions}
          onChange={handleChangeRegions}
          input={<OutlinedInput label={t('common.regions')} />}
          renderValue={(selected) => `${t('common.selected')} ${selected.length}`}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 240,
              },
            },
          }}
          endAdornment={
            regions.length > 0 ? (
              <IconButton onClick={handleRegionsClearClick} size='small' sx={{ mr: 0.5 }}>
                <ClearIcon fontSize='small' />
              </IconButton>
            ) : undefined
          }
        >
          {regionsMap.map((r) => (
            <MenuItem key={`region-${r.id}`} value={r.id} dense sx={{ px: 0.5, py: 0 }}>
              <Checkbox checked={regions.includes(r.id)} />
              <ListItemText primary={<LangText value={r.title} variant='body2' />} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}
