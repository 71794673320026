import { useEffect, useRef, useState } from 'react'
import { Box, Stack, styled, useMediaQuery } from '@mui/material'

import { MapWrapper } from './Map'
import { Content } from './Content'
import { SwitchButton } from '../components'
import { useLandsContext } from '../contexts'

export enum ViewMode {
  MAP,
  LIST,
  DETAIL,
}

export function MainLayout() {
  const isDesktop = useMediaQuery('(min-width:900px)')
  const [mode, setMode] = useState<ViewMode>(ViewMode.MAP)
  const { currentLandId } = useLandsContext()
  const lastMode = useRef(ViewMode.MAP)

  useEffect(() => {
    if (currentLandId) {
      setMode(ViewMode.DETAIL)
    } else {
      setMode(lastMode.current)
      location.hash = ''
    }
  }, [currentLandId])

  return (
    <StyledStack>
      <Content mode={mode} />

      {isDesktop ? (
        <Box flexGrow={2}>
          <MapWrapper />
        </Box>
      ) : (
        <>
          {!currentLandId && mode === ViewMode.MAP && (
            <Box flexGrow={2}>
              <MapWrapper />
            </Box>
          )}
          <SwitchButton
            mode={mode}
            onClick={(m) => {
              setMode(m)
              lastMode.current = m
            }}
          />
        </>
      )}
    </StyledStack>
  )
}

const StyledStack = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  height: '100vh',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    width: '100%',
  },
}))
