import { LandsProvider, LanguageProvider } from './contexts'
import { ThemeProvider } from './theme'
import { MainLayout } from './views'

function App() {
  return (
    <ThemeProvider>
      <LanguageProvider>
        <LandsProvider>
          <MainLayout />
        </LandsProvider>
      </LanguageProvider>
    </ThemeProvider>
  )
}

export default App
