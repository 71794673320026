import { Paper, Stack, styled, useMediaQuery } from '@mui/material'

import { Header } from './Header'
import { LandsList } from './LandsList'
import { LandDetail } from './LandDetail'
import { useLandsContext } from '../contexts'
import { SearchBox } from '../components'
import { ViewMode } from './MainLayout'

const CONTENT_WIDTH = 440

export function Content({ mode }: { mode: ViewMode }) {
  const isDesktop = useMediaQuery('(min-width:900px)')
  const { currentLandId } = useLandsContext()

  return (
    <StyledPaper
      elevation={6}
      square
      sx={{
        height: mode === ViewMode.MAP ? 'auto' : '100%',
      }}
    >
      <Stack overflow='hidden' height='100%'>
        <Header />
        {!currentLandId && <SearchBox />}

        {(mode === ViewMode.LIST || (isDesktop && !currentLandId)) && (
          <StackContainer>
            <LandsList />
          </StackContainer>
        )}
        {mode === ViewMode.DETAIL && currentLandId && (
          <StackContainer>
            <LandDetail id={currentLandId} />
          </StackContainer>
        )}
      </Stack>
    </StyledPaper>
  )
}

const StyledPaper = styled(Paper)(({ theme }) => ({
  width: CONTENT_WIDTH,
  maxWidth: '100%',
  maxHeight: '100%',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}))

const StackContainer = styled(Stack)(() => ({
  height: '100%',
  overflowY: 'auto',
}))
