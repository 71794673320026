import type { GeoJsonObject } from 'geojson'
import { intersection } from 'lodash'

import geoJsonData from './geodata.json'
import animalsData from './animals.json'
import typesData from './types.json'
import regionsData from './regions.json'
import mainData from './main_data.json'
import { IAnimal, IHuntingType, ILand, ILandsFilter, IRegion } from './types'

export function getAnimals(): IAnimal[] {
  return animalsData
}

export function getHuntingTypes(): IHuntingType[] {
  return typesData
}

export function getRegions(): IRegion[] {
  return regionsData
}

export function getMainData(filter: ILandsFilter): ILand[] {
  let result = mainData

  if (filter.search && filter.search.length > 0) {
    result = result.filter(
      (r) =>
        filter.search &&
        (r.title.ru.toLowerCase().includes(filter.search.toLowerCase()) ||
          r.title.kz.toLowerCase().includes(filter.search.toLowerCase()))
    )
  }

  if (filter.regions && filter.regions.length > 0) {
    result = result.filter((r) => r.region && filter.regions?.includes(r.region.id))
  }

  if (filter.animals && filter.animals.length > 0) {
    result = result.filter((r) => {
      return (
        r.animals &&
        intersection(
          filter.animals,
          r.animals.map((a) => a.id)
        ).length === filter.animals?.length
      )
    })
  }

  if (filter.types && filter.types.length > 0) {
    result = result.filter((r) => {
      return (
        r.types &&
        intersection(
          filter.types,
          r.types.map((t) => t.id)
        ).length === filter.types?.length
      )
    })
  }

  return result
}

export function geoJSONDataByLands(lands: ILand[]): GeoJsonObject {
  const landsIds = lands.map((land) => land.id)
  const data = {
    ...geoJsonData,
    features: geoJsonData.features
      .filter((f) => landsIds.includes(f.properties.NN))
      .filter((f) => f.geometry !== null),
  } as GeoJsonObject

  return data
}

export function getLandById(id: number): ILand | undefined {
  return mainData.find((d) => d.id === id)
}

export function getLandGeoById(id: number) {
  return geoJsonData.features.find(({ properties }) => properties.NN === id)
}
